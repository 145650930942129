.payslip[data-v-f61a5e12] {
  width: 100%;
  min-height: 100%;
  background: #fff;
}
@media screen and (min-width: 768px) {
.payslip .main[data-v-f61a5e12] {
    width: 375px;
    height: 667px;
    margin: 0 auto;
}
}
